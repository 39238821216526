import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/work-orders',
        component: Loader(() => import('~/containers/work-orders/List'))
    },
    {
        path: '/work-orders/:id([0-9]+)',
        component: Loader(() => import('~/containers/work-orders/View'))
    }
];

export default routes;
