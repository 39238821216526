import * as actionTypes from './actionTypes';
import * as storage from './storage';

/**
 *  Init Actions
 */
export const init = app => ({
    type: actionTypes.INIT,
    app
});

export const portfolioInit = app => ({
    type: actionTypes.PORTFOLIO_INIT,
    app
});

/**
 * Logout
 */
export const logout = () => ({
    type: actionTypes.LOGOUT
});

/**
 * Portal Actions
 */
export const changePortal = () => ({
    type: actionTypes.CHANGE_PORTAL
});

export const cancelChangePortal = () => ({
    type: actionTypes.CANCEL_CHANGE_PORTAL
});

export const setPortal = portal => {
    storage.setPortal(portal.owner.contactID);

    return {
        type: actionTypes.SET_PORTAL,
        portal
    };
};

/**
 * Portfolio Actions
 */

export const changePortfolio = () => ({
    type: actionTypes.CHANGE_PORTFOLIO
});

export const cancelChangePortfolio = () => ({
    type: actionTypes.CANCEL_CHANGE_PORTFOLIO
});

export const setPortfolio = portfolio => {
    storage.setPortfolio(portfolio.portfolioID);
    return {
        type: actionTypes.SET_PORTFOLIO,
        portfolio
    };
};

/**
 * API Actions
 */
export const startApiCall = () => ({
    type: actionTypes.START_API_CALL
});

export const endApiCall = () => ({
    type: actionTypes.END_API_CALL
});

/**
 * Notify
 */
export const notify = data => ({
    type: actionTypes.NOTIFY,
    notify: data
});
