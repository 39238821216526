import queryString from 'query-string';

import PortalTypes from 'Lib/constants/app/portalTypes';

import * as actions from './actions';
import { initialState } from './reducer';

import agent from '~/agent';
import store from '~/store';

export const LOGIN_URL = 'session_owner_LoginUrl';

const SESSION_TIMEOUT_THRESHOLD = 300; // Refresh Access Token for 5 minutes before it expires

const sessionTimeout = null;

const ROOT_DOMAIN = process.env.DOMAIN;
const CLIENT_ID = process.env.CLIENT_ID;

const getSubdomain = () => {
    var full = window.location.host;
    // Window.location.host is subdomain.domain.com
    var parts = full.split('.');

    return parts[0];
};

const clearSession = () => {
    clearTimeout(sessionTimeout);
    // Dispatch action to clear the session
    store.dispatch(actions.update(initialState));
};

const setSessionTimeout = duration => {
    // clearTimeout(sessionTimeout);
    // sessionTimeout = setTimeout(() => {
    return agent.refreshToken().then(onAuthSuccess).catch(onAuthFailed);
    // }, (duration - SESSION_TIMEOUT_THRESHOLD) * 1000);
};

const onAuthSuccess = response => {
    store.dispatch(actions.update({ isAuthenticated: true }));
    // setSessionTimeout(response.data.expires);
};

const onAuthFailed = exception => {
    clearSession();
    throw exception;
};

const setLoginUrl = () => {
    // Omitting redirect as it's strictly used for the login action below to set the login URL in certain instances
    const { redirect, ...qs } = queryString.parse(window.location.search);

    if (qs.impersonateID) {
        delete qs.impersonateID;
    }

    const path = JSON.stringify({
        pathname: window.location.pathname.substring(14),
        search: queryString.stringify(qs)
    });

    localStorage.setItem(LOGIN_URL, path);
};

export const getLoginUrl = () => {
    return localStorage.getItem(LOGIN_URL);
};

export const clearLoginUrl = () => {
    localStorage.setItem(LOGIN_URL, null);
};

export const login = () => {
    const qs = queryString.parse(window.location.search);

    // If redirect is present, then we need to set the login url
    if (qs.redirect) {
        setLoginUrl();
    }

    const subdomain = getSubdomain();

    // OAuth Login
    const oauthQs = queryString.stringify({
        client_id: CLIENT_ID,
        response_type: 'code',
        subdomain: subdomain,
        portalType: 2
    });

    window.location.assign(`https://account${ROOT_DOMAIN}/oauth2/authorize?${oauthQs}`);
};

export const impersonate = id => {
    const subdomain = getSubdomain();

    window.location.assign(`https://${subdomain}${ROOT_DOMAIN}/api/owner/oauth/impersonate?id=${id}`);
};

export const checkForSession = () => {
    store.dispatch(actions.update({ isAuthenticated: true }));
};

export const requestToken = code => {
    return agent.requestToken({ code }).then(onAuthSuccess).catch(onAuthFailed);
};

export const refreshToken = () => {
    // return agent.refreshToken().then(onAuthSuccess).catch(onAuthFailed);
};

export const logout = () => {
    setLoginUrl();

    // OAuth Login
    const oauthQs = queryString.stringify({
        portalID: PortalTypes.Owner,
        subdomain: getSubdomain()
    });

    return agent
        .revokeToken()
        .then(() => {
            clearSession();
        })
        .finally(() => {
            window.location.assign(`https://account${ROOT_DOMAIN}/oauth2/logout?${oauthQs}`);
        });
};
