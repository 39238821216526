import update from 'immutability-helper';

import PortalTypes from 'Lib/constants/app/portalTypes';

import * as actionTypes from './actionTypes';

export const initialState = {
    isInitialized: false,
    portalTypeID: PortalTypes.Owner
};

export const reducer = (state = initialState, action) => {
    let portfolio;

    switch (action.type) {
        case actionTypes.INIT:
            return {
                ...state,
                ...action.app
            };
        case actionTypes.PORTFOLIO_INIT:
            return update(state, {
                portfolio: { $set: action.app.portfolio },
                portalSettings: { $set: action.app.portalSettings }
            });
        case actionTypes.CHANGE_PORTAL:
            return update(state, {
                changePortal: { $set: true }
            });
        case actionTypes.CANCEL_CHANGE_PORTAL:
            return update(state, {
                changePortal: { $set: false }
            });
        case actionTypes.SET_PORTAL:
            portfolio = action.portal.portfolios.length == 1 ? action.portal.portfolios[0] : null;

            return update(state, {
                portal: { $set: action.portal },
                portfolio: { $set: portfolio },
                changePortal: { $set: false },
                changePortfolio: { $set: portfolio ? false : true }
            });
        case actionTypes.CHANGE_PORTFOLIO:
            return update(state, {
                changePortfolio: { $set: true }
            });
        case actionTypes.CANCEL_CHANGE_PORTFOLIO:
            return update(state, {
                changePortfolio: { $set: false }
            });
        case actionTypes.SET_PORTFOLIO:
            return update(state, {
                portfolio: { $set: action.portfolio },
                changePortfolio: { $set: false }
            });
        default:
            return state;
    }
};

export default reducer;
