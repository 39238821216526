import update from 'immutability-helper';

import DataTable from 'Lib/utilities/DataTable';
import * as actionTypes from './actionTypes';

export const initialState = {};

export const reducer = (state = initialState, action) => {
    if (!action.type || !action.name) {
        return state;
    }

    switch (action.type) {
        case actionTypes.Retrieve:
            return update(state, {
                [action.name]: {
                    $set: {
                        isLoading: true,
                        timestamp: new Date()
                    }
                }
            });
        case actionTypes.Update:
            if (!action.name) {
                return state;
            }
            return update(state, {
                [action.name]: {
                    isLoading: { $set: false },
                    timeout: { $set: action.timeout },
                    timestamp: { $set: new Date() },
                    data: { $set: Array.isArray(action.data) ? new DataTable(action.data, action.getId) : action.data }
                }
            });
        case actionTypes.Remove:
            if (!action.name) {
                return state;
            }
            return update(state, {
                [action.name]: { $set: undefined }
            });
        case actionTypes.Error:
            return update(state, {
                [action.name]: {
                    isLoading: { $set: false },
                    error: { $set: true },
                    message: { $set: action.message }
                }
            });
        default:
            return state;
    }
};

export default reducer;
