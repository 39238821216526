/**
 * Initialize
 */
export const INIT = 'app/INIT';
export const PORTFOLIO_INIT = 'app/PORTFOLIO_INIT';

/**
 * Logout
 */
export const LOGOUT = 'app/LOGOUT';

/**
 * Portal Actions
 */
export const CHANGE_PORTAL = 'app/CHANGE_PORTAL';
export const CANCEL_CHANGE_PORTAL = 'app/CANCEL_CHANGE_PORTAL';
export const SET_PORTAL = 'app/SET_PORTAL';

/**
 * Portfolio Actions
 */
export const CHANGE_PORTFOLIO = 'app/CHANGE_PORTFOLIO';
export const CANCEL_CHANGE_PORTFOLIO = 'app/CANCEL_CHANGE_PORTFOLIO';
export const SET_PORTFOLIO = 'app/SET_PORTFOLIO';

/**
 * API Actions
 */
export const START_API_CALL = 'app/START_API_CALL';
export const END_API_CALL = 'app/END_API_CALL';

/**
 * Notify
 */
export const NOTIFY = 'NOTIFY';
