import Loader from 'Lib/utilities/Loader';

import billsRoutes from './bills';
import chatRoutes from './chat';
import estimatesRoutes from './estimates';
import filesRoutes from './files';
import ledgerRoutes from './ledger';
import maintenanceRoutes from './maintenance';
import paymentRoutes from './payment';
import propertiesRoutes from './properties';
import receiptRoutes from './receipts';
import reportsRoutes from './reports';
import settingsRoutes from './settings';
import statementsRoutes from './statements';
import transactionsRoutes from './transactions';

const routes = [
    {
        path: '/',
        component: Loader(() => import('~/containers/dashboard/Index'))
    },
    ...propertiesRoutes,
    ...reportsRoutes,
    ...statementsRoutes,
    ...transactionsRoutes,
    ...filesRoutes,
    ...paymentRoutes,
    ...receiptRoutes,
    ...maintenanceRoutes,
    ...estimatesRoutes,
    ...ledgerRoutes,
    ...billsRoutes,
    ...chatRoutes,
    ...settingsRoutes
];

export default routes;
