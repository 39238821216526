import * as actions from './actions';
import * as storage from './storage';

import agent from '~/agent';
import store from '~/store';

export const initialize = () => {
    agent.request
        .get('init')
        .then(response => {
            const defaultPortal = storage.getPortal();
            const defaultPortfolio = storage.getPortfolio();
            const { portals, portalAccounts, user, account } = response.data;
            let portal = null;

            if (defaultPortal) {
                portal = portals.find(item => item.owner.contactID == defaultPortal);
            }

            if (!portal) {
                portal = portals.length == 1 ? portals[0] : null;

                if (portal) {
                    storage.setPortal(portal.owner.contactID);
                }
            }

            let portfolio = null;

            if (portal) {
                if (defaultPortfolio) {
                    portfolio = portal.portfolios.find(item => item.portfolioID == defaultPortfolio);
                }

                if (!portfolio) {
                    portfolio = portal && portal.portfolios.length == 1 ? portal.portfolios[0] : null;

                    if (portfolio) {
                        storage.setPortfolio(portfolio.portfolioID);
                    }
                }
            }

            store.dispatch(
                actions.init({
                    isInitialized: true,
                    hasError: false,
                    errorMessage: null,
                    user,
                    portals,
                    portalAccounts,
                    account,
                    portal,
                    portfolio,
                    changePortal: portal ? false : true,
                    changePortfolio: portfolio ? false : true,
                    isImpersonated: response.data.impersonation ? true : false,
                    impersonation: response.data.impersonation,
                    accountingSettings: response.data.accountingSettings,
                    portalSettings: {},
                    portalOwnerSettings: response.data.portalOwnerSettings,
                    brandSettings: response.data.brandSettings,
                    company: response.data.company
                })
            );

            if (portfolio) {
                portfolioInitialize();
            }
        })
        .catch(error => {
            if (error.response?.status == 401) {
                store.dispatch(
                    actions.init({
                        isInitialized: false,
                        hasError: false,
                        doLogin: true,
                        errorMessage: 'Could not initialize the application.'
                    })
                );
            } else {
                store.dispatch(
                    actions.init({
                        hasAuthenticationError: true,
                        isInitialized: false,
                        hasError: true,
                        errorMessage: 'Could not initialize the application.'
                    })
                );
            }
        });
};

export const portfolioInitialize = () => {
    agent.request
        .get('portfolio/init')
        .then(response => {
            const { portfolio = {}, portalSettings = {} } = response.data;

            store.dispatch(
                actions.portfolioInit({
                    isInitialized: true,
                    portfolio,
                    portalSettings
                })
            );
        })
        .catch(() => {
            store.dispatch(
                actions.portfolioInit({
                    isInitialized: false,
                    hasError: true,
                    errorMessage: 'Could not initialize the portfolio'
                })
            );
        });
};

export const changePortal = () => {
    store.dispatch(actions.changePortal());
};

export const cancelChangePortal = () => {
    store.dispatch(actions.cancelChangePortal());
};

export const setPortal = portal => {
    store.dispatch(actions.setPortal(portal));
};

export const changePortfolio = () => {
    store.dispatch(actions.changePortfolio());
};

export const cancelChangePortfolio = () => {
    store.dispatch(actions.cancelChangePortfolio());
};

export const setPortfolio = portfolio => {
    store.dispatch(actions.setPortfolio(portfolio));
};

/**
 * API
 */
export const callApi = (promise, exclude = false) => {
    if (!exclude) {
        store.dispatch(actions.startApiCall());
    }

    promise
        .catch(error => {
            // TODO: check the status code is 401
            if (error.response.status == 401) {
                store.dispatch(actions.logout());
            }
        })
        .finally(() => {
            if (!exclude) {
                store.dispatch(actions.endApiCall());
            }
        });
};
