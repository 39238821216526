import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/properties',
        component: Loader(() => import('~/containers/properties/List'))
    },
    {
        path: '/properties/:id([0-9]+)',
        component: Loader(() => import('~/containers/properties/View'))
    },
    {
        path: '/properties/:propertyID([0-9]+)/units/:unitID([0-9]+)',
        component: Loader(() => import('~/containers/properties/units/View'))
    }
];

export default routes;
