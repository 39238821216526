import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/reports/rent-roll',
        component: Loader(() => import('~/containers/reports/RentRoll.js'))
    }
];

export default routes;
