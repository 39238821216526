import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/payment-methods',
        component: Loader(() => import('~/containers/paymentMethod/List'))
    },
    {
        path: '/payments/pay',
        component: Loader(() => import('~/containers/payments/Pay'))
    },
    {
        path: '/payments',
        component: Loader(() => import('~/containers/payments/View'))
    }
];

export default routes;
