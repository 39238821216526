const PORTAL_KEY = "owner/portal";
const PORTFOLIO_KEY = "owner/portfolio";

export const getPortal = () => {
	return window.localStorage.getItem(PORTAL_KEY)
};

export const setPortal = contactID => {
	window.localStorage.setItem(PORTAL_KEY, contactID);
};

export const getPortfolio = () => {
	return window.localStorage.getItem(PORTFOLIO_KEY);
}

export const setPortfolio = portfolioID => {
	return window.localStorage.setItem(PORTFOLIO_KEY, portfolioID);
};
